import styles from "./Step.module.scss";

export interface IStep {
  title: string;
  description: string;
  choices: string[];
  multiple?: boolean;
  setSelectedOptions: any;
  selectedOptions: any;
}

export const Step = ({
  title,
  description,
  choices = [],
  multiple,
  setSelectedOptions,
  selectedOptions,
}: IStep) => {
  const handleOptionClick = (opt: string) => {
    if (multiple) {
      setSelectedOptions((prevOptions) =>
        prevOptions.includes(opt)
          ? prevOptions.filter((option) => option !== opt)
          : [...prevOptions, opt]
      );
    } else {
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        const existingOptionIndex = updatedOptions.findIndex((option) =>
          choices.includes(option)
        );
        if (existingOptionIndex !== -1) {
          updatedOptions[existingOptionIndex] = opt;
        } else {
          updatedOptions.push(opt);
        }

        return updatedOptions;
      });
    }
  };
  return (
    <>
      <div className={styles.stepText}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={styles.btnContainer}>
        {choices.map((opt) => (
          <button
            key={opt}
            className={`${styles.fillableButton} ${
              selectedOptions.includes(opt) ? styles.selected : ""
            }`}
            onClick={() => handleOptionClick(opt)}
          >
            <div className={styles.buttonTexts}>
              <p className={styles.buttonText}>{opt}</p>
              <p className={styles.buttonText}>{opt}</p>
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

import { FC } from "react";
import styles from "./Choice.module.scss";

interface IChoice {
  choice: string[];
}

export const Choice: FC<IChoice> = ({ choice }) => {
  return (
    <div className={styles.choiceContainer}>
      <div className={`${styles.choice} ${choice[0] ? styles.active : ""}`}>
        <p>{choice[0]}</p>
      </div>
      <div className={`${styles.choice} ${choice[1] ? styles.active : ""}`}>
        <p>{choice[1]}</p>
      </div>
      <div className={`${styles.choice} ${choice[2] ? styles.active : ""}`}>
        <p>{choice[2]}</p>
      </div>
      <div className={`${styles.choice} ${choice[3] ? styles.active : ""}`}>
        <p>{choice[3]}</p>
      </div>
      <div className={`${styles.choice} ${choice[4] ? styles.active : ""}`}>
        <p>{choice[4]}</p>
      </div>
      <div className={`${styles.choice} ${choice[5] ? styles.active : ""}`}>
        <p>{choice[5]}</p>
      </div>
      <div className={`${styles.choice} ${choice[6] ? styles.active : ""}`}>
        <p>{choice[6]}</p>
      </div>
    </div>
  );
};

import { useSphere } from "@react-three/cannon";
import { Sphere } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { PropsWithChildren, useRef } from "react";
import { Vector3 } from "three";

interface IRepelledObject {
  repelDistance?: number;
  repelStrength?: number;
  repelMultiplier?: number;
}

const RepelledObject = ({
  children,
  repelDistance = 2,
  repelStrength = 10,
  repelMultiplier = 1500,
}: PropsWithChildren<IRepelledObject>) => {
  const [ref, api]: any = useSphere((index) => ({
    mass: 50,
    position: [0, 0, 0],
    args: [1.25],
    restitution: 0.1,
    linearDamping: 0.05,
    angularDamping: 0.05,
  }));

  const mouseRef = useRef(null);

  const { size, viewport } = useThree();

  const mouseMovmentMinDistance = 0.05;

  useFrame(({ mouse }) => {
    if (ref.current) {
      const mouseX = (mouse.x * viewport.width) / 2;
      const mouseY = (mouse.y * viewport.height) / 2;

      if (
        mouseRef.current &&
        Math.abs(mouseRef.current[0] - mouseX) <= mouseMovmentMinDistance &&
        Math.abs(mouseRef.current[1] - mouseY) <= mouseMovmentMinDistance
      ) {
        return;
      }

      mouseRef.current = [mouseX, mouseY];

      const objectPosition = new Vector3();
      ref.current.getWorldPosition(objectPosition);

      const distance = objectPosition.distanceTo(
        new Vector3(mouseX, mouseY, 0)
      );

      if (distance < repelDistance) {
        const direction = objectPosition
          .sub(new Vector3(mouseX, mouseY, Math.random() * 2))
          .normalize();

        api.applyForce(
          [
            direction.x * repelStrength * repelMultiplier,
            direction.y * repelStrength * repelMultiplier,
            0,
          ],
          [0, 0, 0]
        );
      }
    }
  });

  return (
    <Sphere ref={ref} args={[1.25]} position={[0, 0, 0]}>
      <meshBasicMaterial color={"white"} opacity={0} transparent />
      {children}
    </Sphere>
  );
};

export default RepelledObject;

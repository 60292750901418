import { useTranslations } from "next-intl";
import styles from "./BackButton.module.scss";

export const BackButton = ({ handleClick }) => {
  const translate = useTranslations("dialog");
  return (
    <button className={`${styles.backButton}`} onClick={handleClick}>
      <span className={styles.hoverLine}>{translate("back")}</span>
      <svg
        className={styles.svgDesktop}
        width="51"
        height="25"
        viewBox="0 0 51 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M49.5 11.5H3M3 11.5L14 2M3 11.5L14 23"
          stroke="#17082F"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className={styles.svgMobile}
        width="28"
        height="25"
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.5 11.6945H4.03125M4.03125 11.6945L12.9022 4.0332M4.03125 11.6945L12.9022 20.9687"
          stroke="#17082F"
          strokeWidth="2.41935"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

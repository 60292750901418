import styles from "./ForwardButton.module.scss";
import { useTranslations } from "next-intl";

export const ForwardButton = ({ onClick, disabled }) => {
  const translate = useTranslations("dialog");
  return (
    <button
      className={`${styles.backButton}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={styles.hoverLine}>{translate("next")}</span>
      <svg
        className={styles.svgDesktop}
        width="51"
        height="25"
        viewBox="0 0 51 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 11.5H48M48 11.5L37 2M48 11.5L37 23"
          stroke="#17082F"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className={styles.svgMobile}
        width="28"
        height="25"
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.03125 11.6945H23.5M23.5 11.6945L14.629 4.0332M23.5 11.6945L14.629 20.9687"
          stroke="#17082F"
          strokeWidth="2.41935"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

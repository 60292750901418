import { MagneticCursor } from "../../MagneticCursor/MagneticCursor";
import styles from "./CloseButton.module.scss";

export const CloseButton = ({ setIsContactOpen }) => {
  return (
    <MagneticCursor>
      <div className={styles.closeBtn}>
        <button onClick={() => setIsContactOpen(false)}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="43"
              width="60"
              height="3.38896"
              transform="rotate(-45 1 43)"
              fill="#17082F"
            />
            <rect
              width="60"
              height="3.38896"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 46 43)"
              fill="#17082F"
            />
          </svg>
        </button>
      </div>
    </MagneticCursor>
  );
};

import { Physics } from "@react-three/cannon";
import { Canvas } from "@react-three/fiber";
import RepelledObject from "../3D/RepelledObject";
import Duck from "../3D/Duck";
import { PerspectiveCamera } from "@react-three/drei";
import Bounds from "../3D/Bounds";
import { useDeviceDetector } from "@/utils/useDeviceDetector";

const DialogScene = () => {
  const { isMobile } = useDeviceDetector();
  return (
    <Canvas
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
      }}
    >
      <Physics gravity={[0, -10, 0]}>
        {isMobile ? (
          <Duck position={[0, -3, -1]} scale={4} />
        ) : (
          <RepelledObject>
            <Duck
              position={[0, -1.2, -1]}
              scale={3}
              cursorAnimations
              cursorAngleLimit={15}
            />
          </RepelledObject>
        )}
        <Bounds />
      </Physics>
      <PerspectiveCamera
        position={[0, 0, 8]}
        rotation={[0, 0, 0]}
        makeDefault
      />
    </Canvas>
  );
};

export default DialogScene;

import styles from "./ButtonSubmit.module.scss";
import { useTranslations } from "next-intl";

export const ButtonSubmit = ({
  filledCounts,
  isLoading,
  sendForm,
  limit = 5,
}) => {
  const translate = useTranslations("dialog");
  return (
    <button
      className={`
      ${styles.submitBtn} 
      ${filledCounts === limit ? styles.hoverButton : ""}
      `}
      disabled={filledCounts !== limit}
      onClick={sendForm}
    >
      {isLoading ? (
        <div className={styles.spinner}>
          <div className={styles.spinnerCircle}>
            <div className={styles.spinnerCircleGradient}></div>
            <div className={styles.spinnerCircleInner}></div>
          </div>
        </div>
      ) : (
        <span className={`${styles.text}`}>{translate("send")}</span>
      )}

      <div className={styles.submitBtnBase}></div>
      <div
        className={`${styles.submitBtnBaseBg}`}
        style={{
          transform: `scaleY(${filledCounts < limit ? filledCounts * 0.2 : filledCounts})`,
        }}
      ></div>
    </button>
  );
};

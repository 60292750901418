import { usePlane } from "@react-three/cannon";
import { useThree } from "@react-three/fiber";
import { useMemo } from "react";

const Plane = ({ color, ...props }) => {
  usePlane(() => ({ ...props }));
  return null;
};

const Bounds = () => {
  const { viewport } = useThree();

  const planePositions = useMemo(() => {
    const { width, height } = viewport;
    return {
      bottom: [0, -height / 2, 0],
      top: [0, height / 2, 0],
      left: [-width / 2, 0, 0],
      right: [width / 2, 0, 0],
      front: [0, 0, -width / 2],
      back: [0, 0, width / 2],
    };
  }, [viewport]);

  return (
    <group>
      <Plane
        color={0x00ff00}
        scale={30}
        position={planePositions.bottom}
        rotation={[-Math.PI / 2, 0, 0]}
      ></Plane>
      <Plane
        color={0x00ff00}
        scale={30}
        position={planePositions.top}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <Plane
        color={0x00ff00}
        scale={30}
        position={planePositions.left}
        rotation={[0, Math.PI / 2, 0]}
      />
      <Plane
        color={0x00ff00}
        scale={30}
        position={planePositions.right}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Plane
        color={0x00ff00}
        scale={30}
        position={planePositions.front}
        rotation={[0, 0, 0]}
      />
      <Plane
        color={0x00ff00}
        scale={30}
        position={planePositions.back}
        rotation={[0, Math.PI, 0]}
      />
    </group>
  );
};

export default Bounds;
